import React from 'react';
import { RichText } from '../richTextOptions';
import { connect } from 'react-redux';
import { getIsPreviewStatus } from '../../state/app';
import PropTypes from 'prop-types';
import VideoJSApp from './Common/VideoJSApp';
import { Image } from './Common/Image';

const BlockVideoDownload = (props) => {
     const { titleVideoDownload, petVideo, isPreview, videoThumbnail, textDescription } = props;
     const petVideoUrl = isPreview ? petVideo?.fields.file?.url : petVideo?.file?.url;
     const videoThumbnailUrl = isPreview ? videoThumbnail?.fields.file?.url : videoThumbnail?.file?.url;

     const handleClick = () => {
          window.open(petVideoUrl, '_blank');
     };
     return (
          <div className="video-download">
               <RichText data={titleVideoDownload} />

               {petVideo ? (
                    <div className="pets-video">
                         <div className="container">
                              <VideoJSApp urlVideo={petVideoUrl} videoThumbnailUrl={videoThumbnailUrl} />
                         </div>

                         <div className="pets-video-download">
                              <button onClick={handleClick}>
                                   <span>Download now</span>
                              </button>
                         </div>
                    </div>
               ): (
                    <div className="pets-image-download">
                          <Image image={videoThumbnail}></Image>
                          <p>{textDescription}</p>
                    </div>
               )}
          </div>
     );
};

BlockVideoDownload.propTypes = {
     isPreview: PropTypes.bool
};

const mapStateToProps = (state) => {
     return {
          isPreview: getIsPreviewStatus(state)
     };
};

const ContentfulBlockVideoDownload = connect(mapStateToProps)(BlockVideoDownload);

export default ContentfulBlockVideoDownload;
